module services {
    export module master {
        export class crewService implements interfaces.master.ICrewService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            getForDropdown(ownerEntityId: number, searchText: string): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {

                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "Crew/GetForDropdown", {
                    ownerEntityId: ownerEntityId,
                    searchText: searchText
                });
            }

            get(crwId: number): ng.resource.IResourceClass<interfaces.master.ICrew> {

                return this.$resource<interfaces.master.ICrew>(this.ENV.DSP_URL + "Crew/Get", {
                    crwId: crwId
                }, {
                        get: {
                            method: "GET",
                            isArray: false,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                        }
                    });
            }
        }
    }
    angular.module("app").service("crewService", services.master.crewService);
}